import { get, put, del, post } from "../../network/Config/apiService";

// Get All Users
const GetAllUsers = async () => {
  try {
    const res = await get("/admin/users");

    const usersData = res.data.data;

    return {
      result: "success",
      message: "Users data fetched Successfully",
      data: usersData,
    };
  } catch (err) {
    return {
      result: "error",
      message: err.response.data.message || "Could not fetch users data",
      data: [],
    };
  }
};

// Get User Details by UID
const GetUserDetails = async (uid) => {
  try {
    const res = await get(`/admin/user/${uid}`);

    const userData = res.data.data;

    return {
      result: "success",
      message: "User data fetched Successfully",
      data: userData,
    };
  } catch (err) {
    return {
      result: "error",
      message: err.response.data.message || "Could not fetch user data",
      data: null,
    };
  }
};

const UpdateUser = async (uid, data) => {
  try {
    await put(`/admin/user/${uid}`, data);

    return {
      result: "success",
      message: "User profile updated successfully",
      data: data,
    };
  } catch (err) {
    return {
      result: "error",
      message: err?.response?.data?.message || "Could not update user profile",
      data: null,
    };
  }
};

const DeleteUser = async (uid) => {
  try {
    await del(`/admin/user/${uid}`);

    return new Promise((resolve) => {
      resolve({
        result: "success",
        message: "User deleted successfully",
      });
    });
  } catch (err) {
    return new Promise((resolve) => {
      resolve({
        result: "error",
        message: err?.response?.data?.message || "Could not delete user",
      });
    });
  }
};

const UpdateUserProfileImage = async (userUuid, image) => {
  try {
    const formData = new FormData();
    formData.append("file", image);

    const res = await post(`/admin/user/${userUuid}/upload-image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return {
      result: "success",
      message: "User profile image updated successfully",
      data: res.data.data,
    };
  } catch (error) {
    return {
      result: "error",
      message: error.response.data.message || error.message || "Error updating profile image",
      data: null,
    };
  }
};

export { GetAllUsers, GetUserDetails, UpdateUser, DeleteUser, UpdateUserProfileImage };
