import { get, post } from "../../network/Config/apiService";

const GetTokensOfUser = async (uid) => {
  try {
    const res = await get(`/admin/tokens/${uid}`);
    const tokensData = res.data?.data.tokens;

    return {
      result: "success",
      message: "Tokens fetched successfully",
      data: tokensData,
    };
  } catch (error) {
    return {
      result: "error",
      message: error?.response?.data?.message || "Failed to fetch tokens",
      data: {},
    };
  }
};

const AddUpdateTokensOfUser = async (uid, tokens) => {
  if (!tokens) {
    throw new Error("Tokens data is required");
  }

  try {
    await post(`/admin/tokens/${uid}`, {
      tokens: tokens,
    });

    return {
      result: "success",
      message: "Tokens updated successfully",
    };
  } catch (error) {
    return {
      result: "error",
      message: error?.response?.data?.message || "Failed to update tokens",
    };
  }
};

export { GetTokensOfUser, AddUpdateTokensOfUser };
